import classNames from 'classnames'
import styles from './styles.module.scss'
import { ArrowDown } from 'component/Icons';

export const WhatStudentsLearnResearch = props => {
	const { data } = props;
	return (
		<section className={styles.whatStudentsLearn}>
			<div className={styles.overlay}></div>
			<div className="container zi-1">
				<div className="row align-items-center">
					<div className="col-12">
						<h3 className={classNames(styles.title, 'text-center')}>{data?.title}</h3>
					</div>
					{data?.sections ? data?.sections.map((section, idx) => (
						<div className='col-12 col-md-6'>
							<h4 className={styles.sectionTitle}>{section.sectionTitle}</h4>
							<div id="accordion">
								{section?.accordion?.map((item, ind) => {
									return (
										<div
											className={classNames(styles.card, "card")}
											key={idx + ind}
										>
											<div
												className={classNames(
													styles.cardHeader,
													"card-header p-0"
												)}
												id={"wsl-" + idx + ind}
											>
												<h5 className="mb-0">
													<button
														className="btn collapsed"
														data-toggle="collapse"
														data-target={"#tc-wsl" + idx + ind}
														aria-expanded="false"
														aria-controls="tc-wsl"
													>
														<span className={styles.chText}>{item.title}</span>
														<ArrowDown />{" "}
													</button>
												</h5>
											</div>

											<div
												id={"tc-wsl" + idx + ind}
												className={classNames(styles.tcCollapse, "collapse")}
												aria-labelledby={"wsl-" + idx + ind}
												data-parent="#accordion"
											>
												<div
													className={classNames(styles.cardBody, "card-body p-20")}
												>
													<p>{item.desc}</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)) : null}
					<div className="col-12 mt-30">
						{data?.paragraphs ? data?.paragraphs?.map(p => (
							<p className={styles.desc}>{p}</p>
						)) : null}
					</div>
				</div>
			</div>
		</section>
	)
}