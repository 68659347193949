import { Fragment } from "react";
import background from "assets/img/home/k-12-research-opportunity.jpg";

export const programBenefits = {
  background: background,
  title: (
    <Fragment>
      Program{" "}
      <span className="text-animated">
        <span>Benefits</span>
      </span>
    </Fragment>
  ),
  keypoints: [
    "Increase college competitiveness",
    "Develop transferable STEM research abilities",
    "Create a research project for college or internship portfolios",
    "Build tangible emerging tech skills",
    "Explore real world applications of AI & Quantum research",
    "Build relationships with faculty & industry mentors",

  ],
  desc: <Fragment>Whether students are looking to learn more about how AI or
    Quantum is applicable to their own interests, about ethics in research, or
    how to get a head start on research before college, they will all gain
    knowledge and experience <span className="text-gold">rarely offered to high school students.</span></Fragment>,
};
