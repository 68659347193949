import { Fragment } from 'react'

export const stepsToApply = {
  title: (
    <Fragment>
      Steps{' '}
      <span className="text-animated">
        <span>to Apply</span>
      </span>
    </Fragment>
  ),
  steps: [
    'Complete the application and upload the Statement of Interest at the link below.',
    'Applicants are not required to submit payment at the time of application.',
    'Applicants will be notified via email on a rolling basis.',
    'If you are selected, acceptance and tuition will be due within 72 hours. Payment plans are available.',
  ],
  links: [
    {
      link: 'https://qubitbyqubit.com/eqci-application',
      text: 'Apply',
      isExternal: true,
    },
  ],
}
