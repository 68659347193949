import { Banner } from 'component/Research/Banner'
import { CreatedBy } from 'component/Research/CreatedBy'
import { HowToApply } from 'component/TrainCourse/CourseOverview/components/HowToApply'
import { LeadInstructors } from 'component/TrainCourse/LeadInstructors'
import { Logistics } from 'component/Research/Logistics'
import { Offerings } from 'component/Courses/Offerings'
import { Professors } from 'component/Research/Professors'
import { ProgramBenefits } from 'component/Research/ProgramBenefits'
import { ProgramCompletion } from 'component/Research/ProgramCompletion'
import { ProgramDetails } from 'component/Research/ProgramDetails'
import { ProgramOverview } from 'component/Research/ProgramOverview'
import { StepsToApply } from 'component/Research/StepsToApply'
import { StudentsTestimonials } from 'component/Research/StudentsTestimonials'
import { Testimonial } from 'component/SummerPrograms/EmergingTechPrograms/components/Testimonial'
import { WhatStudentsLearn } from 'component/Research/WhatStudentsLearn'

import {
  banner,
  createdBy,
  howToApply,
  leadInstructor,
  logistics,
  offerings,
  professors,
  programBenefits,
  programCompletion,
  programDetails,
  programOverview,
  stepsToApply,
  studentsTestimonials,
  whatStudentsLearn,
  techRequirements,
  scholarships,
} from 'data/dsResearch'

import testimonalImg from 'assets/img/about/about-us-splash-bg.jpg'
import susan from 'assets/img/research/miss-susan.png'

import styles from './styles.module.scss'

export const DsResearch = () => {
  return (
    <div className={styles.research}>
      <Banner data={banner} hasOverlay={true} />
      <CreatedBy data={createdBy} />
      <ProgramOverview data={programOverview} />
      <ProgramDetails data={programDetails} />
      <div className="container my-50">
        <Testimonial
          wrapClassName={styles.testimony}
          overlayClassName={styles.overlay}
          background={testimonalImg}
          testimony="It is exciting to see high school students learn and practice data skills before they have even started college considering that students normally gain this level of knowledge at the undergraduate and graduate level."
          testifier="Susan McGregor - Professor, Columbia University Data Science Institute"
          testifierImg={susan}
        />
      </div>
      <ProgramCompletion data={programCompletion} />
      <StudentsTestimonials
        data={studentsTestimonials?.one}
        titleClass={'text-blue-gradient-2'}
        descriptionClass={'text-blue-gradient-2'}
      />
      <WhatStudentsLearn
        data={whatStudentsLearn}
        isBulletInline={true}
        isResearch={true}
      />
      <StudentsTestimonials
        data={studentsTestimonials?.two}
        isImgFirst={true}
        titleClass={'text-blue-gradient-2'}
        descriptionClass={'text-blue-gradient-2'}
      />
      <Offerings data={offerings} iconClassName={styles.icon} />
      <ProgramBenefits data={programBenefits} />
      <Professors data={professors} />
      <LeadInstructors data={leadInstructor} />
      <div className="bg-gray py-50 text-center">
        <div className="container">
          <HowToApply data={howToApply} />
        </div>
      </div>
      <StepsToApply data={stepsToApply} />
      <Logistics data={logistics} hasBgOverlay={true} />
      <div className="bg-gray py-50 text-center">
        <div className="container">
          <HowToApply data={techRequirements} />
        </div>
      </div>
      <div className="bg-gray-2 py-50 text-center">
        <div className="container">
          <HowToApply data={scholarships} />
        </div>
      </div>
    </div>
  )
}
