import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import AOS from 'aos'
import './App.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min'

// Component
import { Header } from './component/Common/Header'
import { Footer } from './component/Common/Footer'
import { ScrollToTop } from './component/Common/ScrollToTop'

import { AboutUs } from './pages/AboutUs'
import { Home } from './pages/Home'
import { Involved } from './pages/Involved'
import { News } from './pages/News/News'
import { Donate } from 'pages/Donate'
import { SummerPrograms } from './pages/SummerPrograms'
import { Courses } from 'pages/Courses'

import { Train } from './pages/Train'
import { TrainOverview } from 'pages/TrainOverview'
import { TrainPD } from './pages/TrainPd'
import { TrainAwsPd } from 'pages/TrainAwsPd'
import { TrainCourse } from 'pages/TrainCourse'
import { TrainDodCourse } from 'pages/TrainDodCourse'
import { TrainSemesterCourse } from 'pages/TrainSemesterCourse'
import { TrainCourseApplication } from 'pages/TrainCourseApplication'
import { TrainPdApplication } from 'pages/TrainPdApplication'
import { TrainAwsPdApplication } from 'pages/TrainAwsPdApplication'
import { TrainTwoWeeksPd } from 'pages/TrainTwoWeeksPd'

import { SchoolPartnerInfo } from 'pages/SchoolPartnerInfo'
import { Research } from 'pages/Research'
import { HighSchoolResearch } from 'pages/HighSchoolResearch'
import { HsResearch } from 'pages/HsResearch'
import { DsResearch } from 'pages/DsResearch'

import { NotFound } from './pages/NotFound'

import { SummerCampRegistration } from './pages/SummerCampRegistration'
import { DSRPApplication } from 'pages/DSRPApplication'
import { EQCIApplication } from 'pages/EQCIApplication'
import { QuantumPdApplication } from 'pages/QuantumPdApplication'
import { HSRPApplication } from 'pages/HSRPApplication'
import { Subscribe } from 'pages/Subscribe'
import { YPAB } from 'pages/YPAB'

function App() {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path={'/'} element={<Home />} exact={true} />
        <Route path={'/about_us'} element={<AboutUs />} />
        <Route path={'/involved'} element={<Involved />} />
        {/* <Route path={'/ypab'} element={<YPAB />} /> */}
        <Route path={'/news'} element={<News />} />
        <Route path={'/donate'} element={<Donate />} />

        <Route path={'/train'} element={<Train />} />
        <Route path={'/train-overview'} element={<TrainOverview />} />
        <Route path={'/train-course'} element={<TrainCourse />} />
        <Route path={'/train-dod-course'} element={<TrainDodCourse />} />
        <Route
          path={'/train-semester-course'}
          element={<TrainSemesterCourse />}
        />
        <Route
          path={'/course-application'}
          element={<TrainCourseApplication />}
        />
        <Route
          path={'/train-aws-pd-application'}
          element={<TrainAwsPdApplication />}
        />
        <Route path={'/train-pd-interest'} element={<TrainPdApplication />} />
        <Route path={'/train-pd'} element={<TrainPD />} />
        <Route path={'/train-2-weeks-pd'} element={<TrainTwoWeeksPd />} />
        <Route path={'/train-aws-pd'} element={<TrainAwsPd />} />

        <Route path={'/school-partner-info'} element={<SchoolPartnerInfo />} />
        <Route path={'/summer-programs'} element={<SummerPrograms />} />
        <Route path={'/courses'} element={<Courses />} />

        <Route path={'/research'} element={<Research />} />
        <Route path={'hs-ai-research'} element={<HighSchoolResearch />} />
        <Route path={'hs-research'} element={<HsResearch />} />
        <Route path={'ds-research'} element={<DsResearch />} />

        <Route
          path={'/summer-camp-registration'}
          element={<SummerCampRegistration />}
        />
        <Route path={'dsrp-application'} element={<DSRPApplication />} />
        <Route path={'eqci-application'} element={<EQCIApplication />} />
        <Route
          path={'quantum-pd-application'}
          element={<QuantumPdApplication />}
        />
        <Route path={'hsrp-application'} element={<HSRPApplication />} />

        <Route path={'subscribe'} element={<Subscribe />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
