import { Fragment } from 'react'
import bg from 'assets/img/research/university-columbia.jpg'

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      <span className="d-block mb-5">
        National Data Science Research program
      </span>
      <i className="d-block mb-5">in collaboration with </i>
      <span className="d-block text-gold">
        Columbia University's Data Science Institute
      </span>
    </Fragment>
  ),
  subHeading:
    <Fragment>
      Develop data science skills | <span className='text-gold'>Conduct cutting-edge research using real-world data</span> | Increase college competitiveness |
    </Fragment>,
  description: (
    <Fragment>
      Grades: Incoming 9<sup>th</sup> &mdash; 12<sup>th</sup> graders and rising college freshmen
      Graduates <br />
      Dates: July 7 - August 8, 2025
    </Fragment>
  ),
  links: [
    {
      linkText: '2025 Interest Form',
      link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
      isExternal: true,
    },
  ],
}
