import background from 'assets/img/research/logistics-bg.jpg'
import { Fragment } from 'react'

export const logistics = {
  background: background,
  title: 'Logistics',
  logisticsList: [
    {
      subTitle: 'Grades',
      desc: 'Incoming 10th - 12th graders and recent high school graduates',
    },
    {
      subTitle: 'Dates',
      desc: 'June 23 - August 1, 2025',
    },
    {
      subTitle: 'Time',
      desc: (
        <Fragment>
          Monday - Friday
          <span className="d-block">12 - 4pm ET (may vary after week 2)</span>
          <small className="text-italic d-block">
            *Must be able to join live sessions
          </small>
        </Fragment>
      ),
    },
    {
      subTitle: 'Location',
      desc: (
        <Fragment>
          <span className="d-block mb-5">
            Training: Virtual with live instruction
          </span>
          <span className="d-block">
            Research with Mentor: Virtual, In-Person or RemoteDates:
          </span>
        </Fragment>
      ),
    },
    {
      subTitle: 'Prerequisites',
      desc: (
        <Fragment>
          <span className="d-block mb-5">
            Basic knowledge in Python programming (variables, loops, functions)
          </span>
          <span className="d-block mb-5">
            TCS’ Introduction to AI or Quantum Computing Course respectively*
          </span>
          <small className="text-italic d-block">
            *Accepted students who have not taken the Intro Course, will need to
            complete a pre-program video tutorial series.
          </small>
        </Fragment>
      ),
    },
    {
      subTitle: 'Tuition',
      desc: (
        <Fragment>
          <span className='d-block mb-5'>$3995</span>
          <span className='d-block mb-5'>Prerequisite tutorial series (if needed): $1000*</span>
          $3995
          <small className="text-italic d-block">
            Scholarships available for students with demonstrated financial need.
          </small>
        </Fragment>
      ),
    },
  ],
}
