import { Fragment } from "react";
import img from "assets/img/research/program-overview.png";

export const programOverview = {
  title: (
    <Fragment>
      Research Program{" "}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  desc: (
    <Fragment>
      The Coding School has developed an unprecedented research experience for
      high school students. Partnered with top tier universities across the country
      including Columbia, University of Maryland, and University of California,
      each student will have a unique learning experience including delving deep and
      gaining real skills, applying those to conducting research, serving as research
      assistants to PhD candidates or professors, and contributing to the fields of
      AI/ML or Quantum research. Students will culminate the program presenting their
      final work at the TCS Summer Research Symposium and with invaluable
      experiences to fill college essays with and showcase in the college application
      process.
      <span className="d-block mt-15">Students have the choice of applying to the <span className="text-bold text-gold">AI</span> or <span className="text-bold text-gold">Quantum</span> Research track.</span>

    </Fragment>
  ),
  img: {
    src: img,
    alt: "Program Overview",
  },
};
