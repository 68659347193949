import { Fragment } from "react";

export const scholarships = {
  title: (
    <Fragment>
      <span className="text-animated">
        <span>Scholarships</span>
      </span>
    </Fragment>
  ),
  descriptions: [
    "Scholarship funds are available on a limited basis to cover the cost of tuition for eligible, US-based students that exemplify need and alignment with The Coding School’s mission. Students from traditionally underrepresented backgrounds in STEM are encouraged to apply. The deadline to apply for a scholarship is May 31. Scholarship awardees will be notified by June 9.",
  ],
};
