export const programDetails = {
  title: 'This five-week program consists of two main components:',
  card: [
    {
      cardTitle: 'Weeks 1-2:',
      cardDesc: [
        "Students participate in a virtual research training program with TCS faculty, reviewing material from TCS’ Introduction to Artificial Intelligence or Introduction to Quantum Computing respectfully, while also learning useful vocabulary and professional skills to prepare to conduct research with their research hosts or mentors.",
      ],
    },
    {
      cardTitle: 'Weeks 3-5:',
      cardDesc: [
        'Students put their emerging technology skills into action while developing and/or assisting researchers with research projects that are overseen by university or industry mentors.',
      ],
    },
  ],
  fullCard: {
    cardTitle: "Optional Self-Study Component for Non-Alumni Students",
    cardDesc: [
      "Accepted students who have not taken either TCS’ Introduction to AI or Introduction to Quantum Computing year-long course will be required to complete an additional component in order to gain the foundational skills needed. This will include a self-paced video series, check-ins, and 1-2 training sessions."
    ]
  },
}
